const ANDROID = "android";
const IOS = "ios";
const ZH = "zh";
const EN = "en";
const MAINNET = "mainnet";
const TESTNET = "testnet";
const PROTOCOL = "protocol";
const PRIVACY = "privacy";
const MNEMONIC = "mnemonic";

const TELEGRAM = "telegram";
const MEDIUM = "medium";
const TWITTER = "twitter";
export const UA = navigator.userAgent;
export const DNS = location.hostname.toLowerCase().split(".");
export const SYSTEM = {
    ANDROID,
    IOS
}
export const LANG = {
    ZH,
    EN
}
export const NET = {
    MAINNET,
    TESTNET
}
export const MASKTYPE = {
    PROTOCOL,
    PRIVACY,
    MNEMONIC
}
export const LINKTYPE = {
    TELEGRAM,
    MEDIUM,
    TWITTER
}