<template>
    <div class="mobile_star_container">
        <div class="mobile_star_content">
            <img src="../../../assets/images/mobile_star.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileStar'
}
</script>

<style lang="less">
.mobile_star_container {
    margin: 0 auto;
    width: 100%;
    .mobile_star_content {
        margin: 0 auto;
        max-width: 7.68rem;
        img {
            width: 100%;
            vertical-align: middle;
        }
    }
}
</style>