// 分设备函数
import {SYSTEM,UA,DNS,NET} from '../constant/constant';
// 判断ios还是android
export function getPlatform(){
    if(UA.match(/(iPad).*OS\s([\d_]+)/) || UA.match(/(iPhone\sOS)\s([\d_]+)/)){
        return SYSTEM.IOS
    }else if(UA.match(/(Android)\s+([\d.]+)/)){
        return SYSTEM.ANDROID;
    }else{
        return null;
    }
}
// 判断主网还是测试网
export function judgeMainOrTest(){
    if(DNS.includes('testnet')){
        return NET.TESTNET;
    }else{
        return NET.MAINNET;
    }
}