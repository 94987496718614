<template>
    <div class="rainbowdesc_content_wrapper">
        <h1 class="rainbowdesc_title">
            <span class="title_first">{{ $t("Lang.titleFirst") }}</span>
            <span class="irisnet_link">{{ $t("Lang.irisnet") }}</span>
            <span>{{ $t("Lang.wallet") }}</span>
        </h1>
        <p class="title_info">{{ $t("Lang.titleInfo") }}</p>
    </div>
</template>

<script>
export default {
    name: "Rainbowdesc",
};
</script>

<style lang="less">
.rainbowdesc_content_wrapper {
    .rainbowdesc_title {
        font-size: 32px;
        font-weight: normal;
    }
    .title_info {
        margin-top: 0.14rem;
        font-size: 18px;
    }
}
</style>