<template>
    <div class="mobile_mask_container">
        <div class="mobile_mask_content">
            <div class="title">
                <span class="title_text">{{ $t("Lang.important_notice") }}</span>
            </div>
            <div class="info">
                <p class="info_title">
                    <span>{{ $t("Lang.dear_users") }}</span>
                </p>
                <p class="part_one">
                    <span>{{ $t("Lang.notice_paragraph_1") }}</span>
                </p>
                <p class="part_two">
                    <span
                        >{{ $t("Lang.notice_paragraph_2")
                        }}<a
                            class="three_docs"
                            :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                            href="javascript:;"
                            @click="switchLang(MASKTYPE.MNEMONIC)"
                            >{{ $t("Lang.notice_link_3") }}</a
                        >{{ $t("Lang.stop_sign") }}</span
                    >
                </p>
                <p class="more_detail">
                    <span
                        >{{ $t("Lang.more_detail") }}
                        <a
                            href="javascript:;"
                            :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                            @click="switchLang(MASKTYPE.PROTOCOL)"
                            >{{ $t("Lang.notice_link_1") }}</a
                        >
                        {{ $t("Lang.and") }}
                        <a
                            href="javascript:;"
                            :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                            @click="switchLang(MASKTYPE.PRIVACY)"
                            >{{ $t("Lang.notice_link_2") }}</a
                        >{{ $t("Lang.stop_sign") }}</span
                    >
                </p>
            </div>
            <button class="btn" @click="updateMask" :style="!isTestnet ? 'background: #456ee4;' : 'background: #817AF3;'">
                {{ $t("Lang.dialogButton") }}
            </button>
            
        </div>
        <div class="close_btn" @click="updateMask">
            <img src="../../assets/images/alert_close.png" />
        </div>
    </div>
</template>

<script>
import { LANG, MASKTYPE, NET } from "@/constant/constant";
import { judgeMainOrTest } from "@/utils/utils";
export default {
    name: "MobileMask",
    data() {
        return {
            LANG,
            MASKTYPE,
        };
    },
    computed: {
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
    },
    methods: {
        updateMask() {
            this.$emit('update:showMask', false);
        },
        // 服务协议及隐私政策语言区分
        switchLang(type) {
            if (type === MASKTYPE.PROTOCOL) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/protocol_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/protocol_en.html");
                }
            } else if (type === MASKTYPE.PRIVACY) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/privacy_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/privacy_en.html");
                }
            } else if (type === MASKTYPE.MNEMONIC) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open(
                        "https://mp.weixin.qq.com/s/ljG4taUvNyFzOb63ZgPd7A"
                    );
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open(
                        "https://medium.com/@irisnet/important-things-when-backing-up-mnemonic-3-dos-and-3-donts-a9dd15797451"
                    );
                }
            }
        },
    }
};
</script>

<style lang="less">
.mobile_mask_container {
    position: relative;
    max-width: 4.09rem;
    background: #fff;
    border-radius: 0.08rem;
    @media (max-width: 490px) {
        max-width: 3.35rem;
    }
    @media (max-width: 365px) {
        max-width: 2.9rem;
    }
    .mobile_mask_content {
        box-sizing: border-box;
        padding: 0.32rem 0.2rem 0.18rem;
        width: 100%;
        .title {
            margin: 0 auto;
            width: 100%;
            height: 0.6rem;
            text-align: center;
            .title_text {
                max-width: 2.17rem;
                font-size: 0.2rem;
                font-weight: 600;
                color: #333333;
                line-height: 0.3rem;
            }
        }
        .info {
            flex: 1;
            margin-top: 0.28rem;
            width: 100%;
            height: 2.59rem;
            line-height: 0.21rem;
            font-size: 0.14rem;
            color: #9BA5BE;
            overflow: auto;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                width: 0;
            }
            .part_one {
                margin: 0.1rem 0;
            }
            .part_two a,
            .more_detail a {
                color: #456ee4;
            }
            .part_two {
                .three_docs {
                    margin-left: 0.03rem;
                }
            }
            .more_detail {
                margin-top: 0.1rem;
            }
        }
        .btn {
            margin-top: 0.28rem;
            width: 100%;
            height: 0.45rem;
            line-height: 0.18rem;
            font-size: 0.16rem;
            font-weight: bold;
            color: #fff;
            background: #456ee4;
            border-radius: 0.04rem;
            border: none;
        }
    }
    .close_btn {
        position: absolute;
        left: 50%;
        bottom: -0.4rem;
        transform: translateX(-50%);
        width: 0.2rem;
        height: 0.2rem;
        img {
            width: 100%;
        }
    }
}
</style>