<template>
    <div class="wrapper">
        <div v-show="!isMobile" class="home_content_wrapper">
            <div class="home_header_content">
                <Header></Header>
            </div>
            <div class="home_main_content">
                <Main></Main>
            </div>
            <div class="home_footer_content">
                <Footer></Footer>
            </div>
        </div>
        <div v-show="isMobile" class="mobile_content_container">
            <div class="mobile_header_content">
                <MobileHeader></MobileHeader>
            </div>
            <div class="mobile_main_content">
                <MobileMain></MobileMain>
            </div>
            <div class="mobile_footer_content">
                <MobileFooter :showMask.sync="showMask"></MobileFooter>
            </div>
            <div class="mobile_mask" v-show="isMobile && showMask">
                <MobileMask :showMask.sync="showMask"></MobileMask>
            </div>
        </div>
    </div>
</template>

<script>
import { SYSTEM } from "@/constant/constant";
import { getPlatform } from "@/utils/utils";
import Header from "components/header/Header";
import Main from "components/main/Main";
import Footer from "components/footer/Footer";
import MobileHeader from "components/header/MobileHeader";
import MobileMain from "components/main/MobileMain";
import MobileFooter from "components/footer/MobileFooter";
import MobileMask from "components/footer/MobileMask";
export default {
    name: "Home",
    data() {
        return {
            showMask: true,
        };
    },
    computed: {
        isMobile() {
            return (
                getPlatform() === SYSTEM.IOS || getPlatform() === SYSTEM.ANDROID
            );
        },
    },
    methods: {
        maskFn() {
            this.showMask = !this.showMask;
        },
    },
    components: {
        Header,
        Main,
        Footer,
        MobileHeader,
        MobileMain,
        MobileFooter,
        MobileMask
    },
    watch: {
        isMobile: {
            handler(newVal) {
                if(!newVal) {
                    document.documentElement.style.minWidth = '560px';
                } else {
                    document.documentElement.style.minWidth = '0';
                    document.documentElement.style.fontFamily = 'ArialMT,Arial-BoldMT,WenQuanYi Micro Hei Mono';
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang='less'>
.wrapper {
    width: 100%;
    height: 100%;
    .home_content_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .home_header_content {
            width: 100%;
            height: 1rem;
            margin: 0 auto;
            max-width: 13.26rem;
        }
        .home_main_content {
            flex: 1 0;
            width: 100%;
            @media (max-width: 768px) {
                box-sizing: border-box;
                padding-left: 0.16rem;
                padding-right: 0.16rem;
            }
        }
        .home_footer {
            width: 100%;
            height: 0.4rem;
        }
    }
    .mobile_content_container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .mobile_header_content {
            height: 1rem;
            @media (max-width: 490px) {
                height: 0.65rem;
            }
        }
        .mobile_main_content {
            flex: 1 0;
        }
        .mobile_footer_content {
            box-sizing: border-box;
            padding: 0.27rem 0;
            @media (min-width: 768px) {
                margin-top: 2.05rem;
            }
            @media (max-width: 768px) {
                margin-top: 2.05rem;
            }
            @media (max-width: 514px) {
                margin-top: 0.97rem;
            }
        }
        .mobile_mask {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(20, 20, 20, 0.73);
        }
    }
}
</style>