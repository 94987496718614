export const Lang = {
    langEn : "English",
    langZh : "繁體中文",
    titleFirst : "Secure BPoS Blockchain Wallet",
    irisnet : "",
    wallet : "",
    googlePlay : "Google Play",
    appleStore : "iOS",
    titleInfo : "Join the Inter-chain Ecosystem with Validators",
    copyrightContent : `Copyright © 2019-${new Date().getFullYear()} Rainbow`,
    mobileTitleFirst : "Secure BPoS Blockchain Wallet",
    mobileClient : "Join the Inter-chain Ecosystem with Validators",
    mobileBeatTitle : "Mainnet wallet will be released soon",
    androidApk : "Android APK",
    ios : "iOS",
    also : "",
    downloadWallet : "Download the Testnet Wallet",
    experience : "to preview",
    downloadIosHint : "This is a temporary enterprise version!",
    warn : "WARN",
    hintHeader : "USE AT YOUR OWN RISK.",
    hintContent : "We strongly suggest to wait for the official release from App Store when it is ready soon. This iOS Enterprise Version is only for testing purpose and might be at risk of failure. If you really opt to use it.",
    mnemonics : "Please be sure to back up your mnemonics",
    hintEnd : "so you can recover the wallet when the release from App store is available.",
    iosSetting : "To install this enterprise version, please 'Trust' this enterprise certificate in Settings - General - Device Management",
    hintBtnContent : "Download Enterprise Version",
    important_notice : "IMPORTANT NOTICE & SERVICE NOTE",
    dear_users : "Dear users,",
    notice_paragraph_1 : "We have noticed there were a few users lost their digital asset due to missed or wrong backuped mnemonics recently. Making backup of the digital wallet is an important step for ensuring the security of digital assets .",
    notice_paragraph_2 : "On a more serious note, please backup your mnemonic accurately after creating the wallet through Rainbow App or CLI. We strongly recommend you to import the wallet by the backupped mnemonics to ensure that your backup mnemonics are valid. Be sure to keep your mnemonic offline and in a safe place ,see",
    notice_link_1 : "Terms",
    notice_link_2 : "Privacy Policy",
    notice_link_3 : "Important things when backing up mnemonic (3 Dos and 3 Do-Nots)",
    more_detail : "More details in",
    and : "and",
    i_know : "I know",
    stop_sign : ".",
    install_course_title : "Installation tutorial for iOS",
    install_step_0 : "Scan the QR code to install",
    install_step_1 : "Click the '点击安装' button",
    install_step_2 : "Click 'Allow'",
    install_step_3 : "Click '蒲公英' to verify the profile",
    install_step_4 : "Click 'Install' top right",
    install_step_5 : "Click 'open'",
    install_step_6 : "Click 'Install' to finish the installation",
    scan_download : "Scan to download",
    important_text : "IMPORTANT NOTICE & SERVICE NOTE",
    hub_content_title : "Supported Blockchains",
    link:"https://t.me/irisnetworkcn",
    dialogButton:"I know"
}