<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
import Home from 'components/home/Home'
export default {
  name: 'App',
  components:{
    Home
  }
}
</script>

<style lang="less">
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
