export const Lang = {
    langZh : "繁體中文",
    langEn : "English",
    titleFirst : "安全便捷的",
    irisnet : "BPoS",
    wallet : "區塊鏈錢包",
    googlePlay : "Google Play",
    appleStore : "iOS",
    titleInfo : "與驗証人共建跨鏈生態",
    copyrightContent : `版權所有 © 2019-${new Date().getFullYear()} Rainbow`,
    mobileTitleFirst : "安全便捷的區塊鏈錢包",
    mobileClient : "與驗証人共建跨鏈生態",
    mobileBeatTitle : "主網錢包即將髮佈",
    androidApk : "Android APK",
    ios : "iOS",
    also : "",
    downloadWallet : "下載測試網錢包",
    experience : "搶先體驗",
    downloadIosHint : "App Store 即將上架",
    warn : "",
    hintHeader : "",
    hintContent : "iOS 企業版僅爲臨時過渡方案，並存在失效風險，我們強烈建議你等待 App Store 版本。如下載使用企業版",
    mnemonics : "請務必備份助記詞",
    hintEnd : "以便在安裝 App Store 版本後恢複錢包",
    iosSetting : "安裝企業版後請在[設置]-[通用]-[設備管理]中點擊信任。",
    hintBtnContent : "下載 iOS 企業版",
    important_notice : "重要提示及服務説明",
    dear_users : "尊敬的用戶：",
    notice_paragraph_1 : "備份錢包是保証數字資産安全的首要環節！雖然在網站、應用內反複提醒用戶必須備份錢包的助記詞，仍有用戶未按提示操作，因不備份或備份錯誤無法恢複錢包而導緻數字資産損失。",
    notice_paragraph_2 : "我們再次鄭重提醒：無論使用 Rainbow App 還是區塊鏈命令行創建錢包後，立即備份好您的 24 個英文單詞的助記詞，我們強烈建議您備份後通過“導入錢包”的方式確保備份正確無誤、實際有效，並將助記詞妥善離線保管，參見",
    notice_link_1 : "服務協議",
    notice_link_2 : "隱私政策",
    notice_link_3 : "《助記詞備份三要三不要》",
    more_detail : "詳見",
    and : "及",
    i_know : "知道了",
    stop_sign : "。",
    install_course_title : "iOS 安裝教程",
    install_step_0 : "掃碼安裝",
    install_step_1 : "點擊安裝",
    install_step_2 : "點擊允許",
    install_step_3 : "點擊'蒲公英'驗証",
    install_step_4 : "點擊右上角'安裝'",
    install_step_5 : "點擊'打開'",
    install_step_6 : "點擊'安裝'",
    scan_download : "掃碼下載",
    important_text : "重要提示及説明",
    hub_content_title : "支持的區塊鏈",
    dialogButton:"我知道了"
}