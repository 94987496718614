<template>
    <div class="mobile_main_container">
        <div class="mobile_main_desc_container">
            <MobileDesc></MobileDesc>
        </div>
        <div class="mobile_star_container">
            <MobileStar></MobileStar>
        </div>
        <div class="mobile_hub_container">
            <MobileHub></MobileHub>
        </div>
        <div class="mobile_down_container">
            <MobileDown></MobileDown>
        </div>
    </div>
</template>

<script>
import MobileDesc from './rainbowdesc/MobileDesc';
import MobileStar from './star/MobileStar';
import MobileHub from './hub/MobileHub';
import MobileDown from './download/MobileDown';
export default {
    name: 'MobileMain',
    components: {
        MobileDesc,
        MobileStar,
        MobileHub,
        MobileDown
    }
}
</script>

<style lang="less">
.mobile_main_container {
    box-sizing: border-box;
    padding-top: 1.16rem;
    width: 100%;
    height: 100%;
    .mobile_star_container {
        margin-top: -0.19rem;
    }
    .mobile_hub_container {
        margin-top: 0.43rem;
    }
    .mobile_down_container {
        margin-top: 0.36rem;
    }
}
</style>