<template>
    <div class="mobile_hub_content_wrapper">
        <p class="mobile_hub_content_title">
            {{ $t("Lang.hub_content_title") }}
        </p>
        <ul class="mobile_hub_content">
            <li class="mobile_hub_item" v-show="!isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/irisLog.png"
                    alt=""
                />
                <a
                    class="mobile_href_content"
                    href="https://www.irisnet.org/"
                    target="_blank"
                    >IRIS Hub
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="mobile_hub_item" v-show="!isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/atom_icon.png"
                    alt=""
                />
                <a
                    class="mobile_href_content"
                    href="https://cosmos.network/"
                    target="_blank"
                    >Cosmos Hub
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="mobile_hub_item biff" v-show="isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/bifrostLog.png"
                    alt=""
                />
                <a
                    class="mobile_href_content"
                    href="https://bifrost.iobscan.io/"
                    target="_blank"
                    >Bifrost
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="mobile_hub_item" v-show="isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/stargateLog.png"
                    alt=""
                />
                <a
                    class="mobile_href_content"
                    href="https://stargate.iobscan.io/"
                    target="_blank"
                    >Stargate
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="mobile_hub_item">
                <img
                    class="hub_img"
                    src="../../../assets/images/binance_icon.png"
                    alt=""
                />
                <a
                    class="mobile_href_content"
                    href="https://www.binance.org"
                    target="_blank"
                    >Binance Chain
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { NET } from "@/constant/constant";
import { judgeMainOrTest } from "@/utils/utils";
export default {
    name: "MobileHub",
    computed: {
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
    },
};
</script>

<style lang="less">
.mobile_hub_content_wrapper {
    margin: 0 auto;
    max-width: 4.84rem;
    @media (max-width: 600px) {
        margin: 0.2rem auto 0;
    }
    .mobile_hub_content_title {
        line-height: 0.18rem;
        font-size: 0.16rem;
        font-weight: normal;
        @media (max-width: 600px) {
            text-align: center;
        }
    }
    .mobile_hub_content {
        display: flex;
        margin: 0.16rem auto 0;
        line-height: 0.2rem;
        @media (max-width: 600px) {
            justify-content: center;
            align-items: center;
            margin-top: 0.2rem;
        }
        @media (max-width: 514px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 0.14rem;
            max-width: 3.45rem;
        }
        @media (max-width: 375px) {
            max-width: 2.9rem;
        }
        .mobile_hub_item {
            margin-left: 0.5rem;
            @media (max-width: 514px) {
                margin-left: 0rem;
                &:nth-of-type(even) {
                    justify-self: end;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            .hub_img {
                margin-right: 0.1rem;
                width: 0.2rem;
                height: 0.2rem;
                vertical-align: middle;
            }
            .mobile_href_content {
                line-height: 0.16rem;
                font-size: 14px;
                color: #fff;
            }
        }
        .biff {
            margin-left: 0;
        }
    }
}
</style>