<template>
    <div class="footer_content_wrapper">
        <div class="footer_content_container">
            <div class="footer_content">
                <div class="footer_content_left">
                    <div class="protocol_content_wrapper">
                        <span
                            class="user_protocol"
                            @click="switchLang('protocol')"
                            >{{ $t("Lang.notice_link_1") }}</span
                        >
                        <i class="line">|</i>
                        <span
                            class="privacy_policy"
                            @click="switchLang('privacy')"
                            >{{ $t("Lang.notice_link_2") }}</span
                        >
                        <i class="line">|</i>
                        <span class="important_tooltip_btn" @click="maskFn">{{
                            $t("Lang.important_notice")
                        }}</span>
                    </div>
                </div>
                <div class="footer_content_center">
                    {{ $t("Lang.copyrightContent") }}
                </div>
                <div class="footer_content_right">
                    <ul class="link_content_wrapper">
                        <li class="link_item telegram_link_content">
                            <img
                                class="telegram_img"
                                src="../../assets/images/irisnet_telegram.png"
                                @click="openFn(LINKTYPE.TELEGRAM)"
                            />
                        </li>
                        <li class="link_item medium_link_content">
                            <img
                                class="irisnet_medium_img"
                                src="../../assets/images/irisnet_medium.png"
                                @click="openFn(LINKTYPE.MEDIUM)"
                            />
                        </li>
                        <li class="link_item twitter_link_content">
                            <img
                                class="twitter_img"
                                src="../../assets/images/twitter.png"
                                @click="openFn(LINKTYPE.TWITTER)"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <transition name="mask">
            <div class="mask" v-show="!isMobile && showMask" :style="!isTestnet ? 'background: #0c1d47;' : 'background: #151246;'">
                <div class="mask_container">
                    <div class="mask_content">
                        <div class="title">
                            <img
                                v-show="!isTestnet"
                                class="volume"
                                src="../../assets/images/volume-up.png"
                            />
                            <img
                                v-show="isTestnet"
                                class="volume"
                                src="../../assets/images/volume-up-testnet.png"
                            />
                            <span class="title_desc">{{
                                $t("Lang.important_notice")
                            }}</span>
                        </div>
                        <img
                            class="close"
                            @click="showMask = false"
                            src="../../assets/images/alert_close.png"
                        />
                        <div class="info">
                            <p class="info_title">
                                <span>{{ $t("Lang.dear_users") }}</span>
                            </p>
                            <p class="part_one">
                                <span>{{ $t("Lang.notice_paragraph_1") }}</span>
                            </p>
                            <p class="part_two">
                                <span
                                    >{{ $t("Lang.notice_paragraph_2")
                                    }}<a
                                        class="three_docs"
                                        :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                                        href="javascript:;"
                                        @click="switchLang(MASKTYPE.MNEMONIC)"
                                        >{{ $t("Lang.notice_link_3") }}</a
                                    >{{ $t("Lang.stop_sign") }}</span
                                >
                            </p>
                            <p class="more_detail">
                                <span
                                    >{{ $t("Lang.more_detail") }}
                                    <a
                                        href="javascript:;"
                                        :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                                        @click="switchLang(MASKTYPE.PROTOCOL)"
                                        >{{ $t("Lang.notice_link_1") }}</a
                                    >
                                    {{ $t("Lang.and") }}
                                    <a
                                        href="javascript:;"
                                        :style="!isTestnet ? 'color: #456ee4;' : 'color: #817AF3;'"
                                        @click="switchLang(MASKTYPE.PRIVACY)"
                                        >{{ $t("Lang.notice_link_2") }}</a
                                    >{{ $t("Lang.stop_sign") }}</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { getPlatform, judgeMainOrTest } from "@/utils/utils";
import { LANG, SYSTEM, NET, MASKTYPE, LINKTYPE } from "@/constant/constant";
export default {
    name: "Footer",
    data() {
        return {
            showMask: true,
            MASKTYPE,
            LINKTYPE,
        };
    },
    computed: {
        // 判断系统
        isMobile() {
            return (
                getPlatform() === SYSTEM.IOS || getPlatform() === SYSTEM.ANDROID
            );
        },
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
    },
    methods: {
        maskFn() {
            this.showMask = !this.showMask;
        },
        // 服务协议及隐私政策语言区分
        switchLang(type) {
            if (type === MASKTYPE.PROTOCOL) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/protocol_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/protocol_en.html");
                }
            } else if (type === MASKTYPE.PRIVACY) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/privacy_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/privacy_en.html");
                }
            } else if (type === MASKTYPE.MNEMONIC) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open(
                        "https://mp.weixin.qq.com/s/ljG4taUvNyFzOb63ZgPd7A"
                    );
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open(
                        "https://medium.com/@irisnet/important-things-when-backing-up-mnemonic-3-dos-and-3-donts-a9dd15797451"
                    );
                }
            }
        },
        openFn(type) {
            if (type === LINKTYPE.TELEGRAM) {
                window.open("https://t.me/irisnetworkcn");
            } else if (type === LINKTYPE.MEDIUM) {
                window.open("https://medium.com/irisnet-blog");
            } else if (type === LINKTYPE.TWITTER) {
                window.open("https://twitter.com/WalletRainbow");
            }
        },
    },
};
</script>

<style lang="less">
.footer_content_wrapper {
    width: 100%;
    height: 0.4rem;
    font-size: 14px;
    @media (max-width: 800px) {
        box-sizing: border-box;
        padding: 0.16rem;
        height: auto;
    }
    .footer_content_container {
        margin: 0 auto;
        max-width: 9.3rem;
        height: 100%;
        @media (max-width: 960px) {
            box-sizing: border-box;
            padding-left: 0.48rem;
            padding-right: 0.48rem;
        }
        .footer_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            width: 100%;
            color: rgba(255, 255, 255, 0.7);
            @media (max-width: 800px) {
                flex-direction: column;
            }
            .footer_content_left {
                text-align: center;
                .protocol_content_wrapper {
                    width: 100%;
                    font-size: 14px;
                    .user_protocol,
                    .privacy_policy,
                    .important_tooltip_btn {
                        cursor: pointer;
                        &:hover {
                            color: #fff;
                        }
                    }
                    .line {
                        display: inline-block;
                        margin: auto 0.2rem;
                        width: 0.01rem;
                        height: 0.15rem;
                        font-style: normal;
                    }
                }
            }
            .footer_content_center {
                text-align: center;
                @media (max-width: 800px) {
                    margin-top: 0.16rem;
                }
            }
            .footer_content_right {
                display: flex;
                justify-content: space-between;
                @media (max-width: 800px) {
                    margin-top: 0.16rem;
                }
                .link_content_wrapper {
                    display: flex;
                    align-items: center;
                    .link_item {
                        height: 0.2rem;
                        margin-left: 0.2rem;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                    .telegram_link_content {
                        width: 0.22rem;
                        .telegram_img {
                            display: inline-block;
                            width: 100%;
                            opacity: 0.7;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                    .medium_link_content {
                        width: 0.22rem;
                        .irisnet_medium_img {
                            display: inline-block;
                            width: 100%;
                            opacity: 0.7;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                    .twitter_link_content {
                        width: 0.26rem;
                        .twitter_img {
                            display: inline-block;
                            width: 100%;
                            opacity: 0.7;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .mask {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 3.06rem;
        @media (max-width: 670px) {
            height: auto;
        }
        .mask_container {
            margin: 0 auto;
            max-width: 13.26rem;
            min-width: 5.6rem;
            height: 100%;
            @media (max-width: 1350px) {
                box-sizing: border-box;
                padding-left: 0.48rem;
                padding-right: 0.48rem;
            }
            .mask_content {
                position: relative;
                box-sizing: border-box;
                padding: 0.35rem 0 0.2rem;
                width: 100%;
                height: 100%;
                .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    text-align: center;
                    .volume {
                        display: inline-block;
                        width: 0.23rem;
                        vertical-align: middle;
                    }
                    .title_desc {
                        display: inline-block;
                        margin-left: 0.13rem;
                    }
                }
                .close {
                    position: absolute;
                    top: 0.25rem;
                    right: -0.24rem;
                    width: 0.24rem;
                    height: 0.24rem;
                    cursor: pointer;
                    @media (max-width: 400px) {
                        right: 0;
                    }
                }
                .info {
                    margin: 0.36rem auto 0;
                    max-width: 8.01rem;
                    min-height: 1.9rem;
                    line-height: 0.2rem;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.7);
                    .part_one {
                        margin: 0.2rem auto;
                    }
                    .part_two {
                        .three_docs {
                            margin-left: 0.03rem;
                        }
                    }
                    .more_detail {
                        margin-top: 0.1rem;
                    }
                }
            }
        }
    }
}
</style>