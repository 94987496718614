<template>
    <div class="mobile_header_container">
        <div class="mobile_header_content_container">
            <div class="header_logo">
                <img
                    v-show="!isTestnet"
                    class="mainnet_logo"
                    src="../../assets/images/rainbowlogo.png"
                    alt=""
                />
                <img
                    v-show="isTestnet"
                    class="testnet_logo"
                    src="../../assets/images/Testnet.png"
                    alt=""
                />
            </div>
            <!-- <div class="lang_block">
                <div
                    class="en_btn"
                    :class="changeColor ? 'change_color' : ''"
                    @click="langFn(LANG.EN)"
                >
                    <span class="en_btn_info">{{ $t("Lang.langEn") }}</span>
                </div>
                <div class="line"></div>
                <div
                    class="zh_btn"
                    :class="!changeColor ? 'change_color' : ''"
                    @click="langFn(LANG.ZH)"
                >
                    <span class="zh_btn_info">{{ $t("Lang.langZh") }}</span>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { LANG, NET } from "../../constant/constant";
import { judgeMainOrTest } from "../../utils/utils";
export default {
    name: "Header",
    data() {
        return {
            LANG,
        };
    },
    computed: {
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
        changeColor() {
            return this.$i18n.locale === LANG.EN;
        }
    },
    methods: {
        langFn(lang) {
            if (lang === LANG.ZH) {
                this.$i18n.locale = LANG.ZH;
            } else {
                this.$i18n.locale = LANG.EN;
            }
        },
    },
};
</script>

<style lang='less'>
.mobile_header_container {
    width: 100%;
    height: 100%;
    .mobile_header_content_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        height: 100%;
        .header_logo {
            height: 0.4rem;
            @media (max-width: 490px) {
                height: 0.25rem;
            }
            img {
                height: 100%;
            }
        }
        .lang_block {
            display: flex;
            align-items: center;
            @media (max-width: 490px) {
                height: 0.25rem;
            }
            .en_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 0.86rem;
                height: 0.35rem;
                background: transparent;
                border-radius: 0.18rem;
                @media (max-width: 490px) {
                    width: 0.6rem;
                    height: 0.25rem;
                }
                .en_btn_info {
                    font-size: 0.16rem;
                    font-weight: 400;
                    line-height: 0.22rem;
                    @media (max-width: 490px) {
                        font-size: 0.12rem;
                        line-height: 0.14rem;
                    }
                }
            }
            .line {
                display: inline-block;
                margin: 0 0.25rem;
                width: 0.01rem;
                height: 0.17rem;
                background: #fff;
                @media (max-width: 490px) {
                    margin: 0 0.13rem;
                    height: 0.14rem;
                }
                @media (max-width: 374px) {
                    margin: 0 0.1rem;
                    height: 0.14rem;
                }
            }
            .zh_btn {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.09rem 0.17rem;
                background: transparent;
                border-radius: 0.18rem;
                @media (max-width: 490px) {
                    padding: 0.05rem 0.1rem;
                }
                @media (max-width: 374px) {
                    padding: 0.05rem 0.08rem;
                }
                .zh_btn_info {
                    font-size: 0.16rem;
                    font-weight: 400;
                    line-height: 0.22rem;
                    @media (max-width: 490px) {
                        font-size: 0.12rem;
                        line-height: 0.14rem;
                    }
                }
            }

            .change_color {
                color: #456EE4;
                background: #fff;
            }
        }
    }
}
</style>