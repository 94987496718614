<template>
    <div class="main_content_wrapper">
        <div class="main_content">
            <div class="content_top">
                <Rainbowdesc></Rainbowdesc>
                <Hub></Hub>
                <Download></Download>
            </div>
        </div>
    </div>
</template>

<script>
import Rainbowdesc from "components/main/rainbowdesc/Rainbowdesc";
import Hub from "components/main/hub/Hub";
import Download from "components/main/download/Download";
export default {
    name: "Main",

    components: {
        Rainbowdesc,
        Hub,
        Download
    },
};
</script>

<style lang='less'>
.main_content_wrapper {
    position: relative;
    flex: 1 0;
    margin: 0 auto;
    max-width: 13.26rem;
    height: 100%;
    .main_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 5.6rem;
        background: url(../../assets/images/bglogo.png) no-repeat center / cover;
        .content_top {
            margin: -0.2rem auto 0;
            max-width: 4.72rem;
        }
    }
}
// .main_content_wrapper {
//   flex: 1 0;
//   position: relative;
//   margin-top: 1.75rem;
//   margin-bottom: 1.85rem;
// //   .bg_logo {
// //     width: 14.85rem;
// //     // height: 5.9rem;
// //   }
//   .main_content {
//     position: absolute;
//     top: -0.18rem;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 5.86rem;
//   }
// }
</style>