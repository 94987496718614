import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import proConfig from '../proConfig/proConfig'
import {Icon,Button,Popover} from "element-ui"
import {LANG} from './constant/constant'
const {ZH,EN}  = LANG;
Vue.use(Icon)
Vue.use(Button)
Vue.use(Popover)
Vue.use(VueI18n)
locale.use(lang)

const i18n = new VueI18n({
  locale:proConfig.lang === EN ? EN : ZH,
  messages: {
    "zh" : require('../i18n/zh'),
    "en" : require('../i18n/en')
  }
})
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
