<template>
    <div class="mobile_download_content_wrapper">
        <div class="mobile_download_content">
            <div
                class="btn google_play_btn"
                v-show="!isTestnet && (!isMobile || isAndroid)"
            >
                <a
                    class="down_img"
                    href="https://play.google.com/store/apps/details?id=rainbow.wallet"
                    target="_blank"
                >
                    <img
                        class="img"
                        src="../../../assets/images/GooglePlay.png"
                        alt=""
                    />
                </a>
            </div>
            <div
                class="btn app_store_btn"
                v-show="!isTestnet && (!isMobile || isIos)"
            >
                <a
                    class="down_img"
                    :href="
                        ENV.VUE_APP_IOS_URL
                            ? ENV.VUE_APP_IOS_URL
                            : 'javascript:;'
                    "
                    :target="ENV.VUE_APP_IOS_URL ? '_blank' : ''"
                >
                    <img
                        class="img"
                        src="../../../assets/images/App.png"
                        alt=""
                    />
                </a>
            </div>
            <div
                class="btn testflight_btn"
                :class="isTestnet ? 'btn_testnet' : ''"
                v-show="isTestnet && (!isMobile || isIos)"
            >
                <a
                    class="down_img"
                    :href="
                        ENV.VUE_APP_TESTFLIGHT_URL
                            ? ENV.VUE_APP_TESTFLIGHT_URL
                            : 'javascript:;'
                    "
                    :target="ENV.VUE_APP_TESTFLIGHT_URL ? '_blank' : ''"
                >
                    <img
                        class="img testflight"
                        src="../../../assets/images/Testflight.png"
                        alt=""
                    />
                </a>
            </div>
            <div
                class="btn android_btn"
                :class="isTestnet ? 'android_btn_testnet' || 'btn_testnet' : ''"
                v-show="!isMobile || isAndroid"
            >
                <a
                    class="down_img"
                    :href="
                        ENV.VUE_APP_ANDROID_URL
                            ? ENV.VUE_APP_ANDROID_URL
                            : 'javascript:;'
                    "
                    :target="ENV.VUE_APP_ANDROID_URL ? '_blank' : ''"
                >
                    <img
                        class="img"
                        src="../../../assets/images/Android.png"
                        alt=""
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { SYSTEM, NET } from "@/constant/constant";
import { getPlatform, judgeMainOrTest } from "@/utils/utils";
export default {
    name: "MobileDown",
    data() {
        return {
            ENV: '',
        };
    },
    computed: {
        // 判断系统
        isIos() {
            return getPlatform() === SYSTEM.IOS;
        },
        isAndroid() {
            return getPlatform() === SYSTEM.ANDROID;
        },
        isMobile() {
            return (
                getPlatform() === SYSTEM.IOS || getPlatform() === SYSTEM.ANDROID
            );
        },
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
    },
    mounted () {
        this.ENV = process.env;
    }
};
</script>

<style lang="less">
.mobile_download_content_wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 4.84rem;
    @media (max-width: 514px) {
        max-width: 3.45rem;
    }
    @media (max-width: 514px) {
        max-width: 3.45rem;
    }
    .mobile_download_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 375px) {
            flex-direction: column;
            box-sizing: border-box;
            padding-left: 0.16rem;
            padding-right: 0.16rem;
        }
        .google_play_btn, .android_btn {
            width: 2.37rem;
            height: 0.46rem;
            background: #071B49;
            border: 0.01rem solid #456EE4;
            border-radius: 0.04rem;
            @media (max-width: 514px) {
                width: 1.67rem;
            }
            @media (max-width: 375px) {
                width: 100%;
            }
            .down_img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                img {
                    display: inline-block;
                    // width: 1.11rem;
                    height: 0.27rem;
                    vertical-align: middle;
                }
            }
        }
        .android_btn {
            @media (max-width: 375px) {
                margin-top: 0.16rem;
            }
        }
        .android_btn_testnet {
            width: 100%;
            height: 0.46rem;
            background: #151246;
            border: 0.01rem solid #817AF3;
            border-radius: 0.04rem;
            .down_img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                img {
                    display: inline-block;
                    height: 0.27rem;
                    vertical-align: middle;
                }
            }
        }
        .app_store_btn {
            width: 100%;
            height: 0.46rem;
            background: #071B49;
            border: 0.01rem solid #456EE4;
            border-radius: 0.04rem;
            .down_img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                img {
                    display: inline-block;
                    height: 0.27rem;
                    vertical-align: middle;
                }
            }
        }
        .testflight_btn {
            width: 100%;
            height: 0.46rem;
            background: #151246;
            border: 0.01rem solid #817AF3;
            border-radius: 0.04rem;
            .down_img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                img {
                    display: inline-block;
                    height: 0.27rem;
                    vertical-align: middle;
                }
            }
        }

        // display: grid;
        // grid-template-columns: repeat(4,1fr);
        // align-items: center;
        // @media (max-width: 768px) {
        //     grid-template-columns: repeat(2,1fr);
        //     grid-row-gap: 0.24rem;
        // }
        // .btn {
        //     margin-right: 0.2rem;
        //     width: 1.6rem;
        //     height: 0.46rem;
        //     background: #071b49;
        //     border: 0.01rem solid #456ee4;
        //     border-radius: 0.04rem;
        //     .down_img {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 1.6rem;
        //         height: 0.46rem;
        //         .img {
        //             width: 1.1rem;
        //             height: 0.3rem;
        //         }
        //     }
        //     &:last-child {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 0.46rem;
        //         height: 0.46rem;
        //         .el-popover__reference-wrapper {
        //             .el-button {
        //                 padding: 0;
        //                 width: 0.46rem;
        //                 height: 0.46rem;
        //                 background: transparent;
        //                 border: 0;
        //                 outline: 0;
        //                 span {
        //                     .scan_img {
        //                         width: 0.34rem;
        //                         height: 0.34rem;
        //                         vertical-align: middle;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // .btn_testnet {
        //     margin-right: 0.1rem;
        //     .down_img {
        //         .img {
        //             height: 0.24rem;
        //         }
        //     }
        // }
        // .btn_testnet,
        // .android_btn_testnet {
        //     width: 2.36rem;
        //     height: 0.46rem;
        //     .down_img {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 2.36rem;
        //         height: 0.46rem;
        //     }
        // }
    }
}
</style>