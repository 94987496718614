<template>
    <div class="mobile_desc_content_wrapper">
        <h1 class="mobile_desc_title">
            <span class="title_first">{{ $t("Lang.titleFirst") }}</span>
            <span class="irisnet_link">{{ $t("Lang.irisnet") }}</span>
            <span>{{ $t("Lang.wallet") }}</span>
        </h1>
        <p class="title_info">{{ $t("Lang.titleInfo") }}</p>
    </div>
</template>

<script>
export default {
    name: "MobileDesc",
};
</script>

<style lang="less">
.mobile_desc_content_wrapper {
    margin: 0 auto;
    max-width: 4.84rem;
    .mobile_desc_title {
        font-size: 0.32rem;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 0.37rem;
        @media (max-width: 600px) {
            font-size: 0.24rem;
            line-height: 0.26rem;
            text-align: center;
        }
        @media (max-width: 490px) {
            font-size: 0.2rem;
            line-height: 0.23rem;
        }
    }
    .title_info {
        margin-top: 0.14rem;
        font-size: 0.18rem;
        color: #FFFFFF;
        line-height: 0.21rem;
        @media (max-width: 600px) {
            font-size: 0.14rem;
            line-height: 0.16rem;
            text-align: center;
        }
        @media (max-width: 490px) {
            font-size: 0.12rem;
            line-height: 0.14rem;
        }
    }
}
</style>