<template>
    <div class="header_content_wrapper">
        <div class="header_content">
            <div class="header_logo">
                <img
                    v-show="!isTestnet"
                    class="mainnet_logo"
                    src="../../assets/images/rainbowlogo.png"
                    alt=""
                />
                <img
                    v-show="isTestnet"
                    class="testnet_logo"
                    src="../../assets/images/Testnet.png"
                    alt=""
                />
            </div>
            <!-- <div class="lang_block">
                <div
                    class="en_btn"
                    :class="changeColor ? 'change_color' : ''"
                    @click="langFn(LANG.EN)"
                >
                    <span class="en_btn_info">{{ $t("Lang.langEn") }}</span>
                </div>
                <div class="line"></div>
                <div
                    class="zh_btn"
                    :class="!changeColor ? 'change_color' : ''"
                    @click="langFn(LANG.ZH)"
                >
                    <span class="zh_btn_info">{{ $t("Lang.langZh") }}</span>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { LANG, NET } from "../../constant/constant";
import { judgeMainOrTest } from "../../utils/utils";
export default {
    name: "Header",
    data() {
        return {
            LANG,
        };
    },
    computed: {
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
        changeColor() {
            return this.$i18n.locale === LANG.EN;
        }
    },
    methods: {
        langFn(lang) {
            if (lang === LANG.ZH) {
                this.$i18n.locale = LANG.ZH;
            } else {
                this.$i18n.locale = LANG.EN;
            }
        },
    },
};
</script>

<style lang='less'>
.header_content_wrapper {
    width: 100%;
    height: 1rem;
    .header_content {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 13.15rem;
        height: 100%;
        line-height: 1rem;
        .header_logo {
            padding-left: 0.2rem;
            height: 0.4rem;
            .mainnet_logo,
            .testnet_logo {
                height: 100%;
                vertical-align: middle;
            }
        }
        .lang_block {
            display: flex;
            align-items: center;
            padding-right: 0.2rem;
            font-size: 16px;
            &:hover {
                cursor: pointer;
            }
            .en_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 0.86rem;
                height: 0.35rem;
                background: transparent;
                border-radius: 0.18rem;
                box-shadow: 0px 2px 36px 0px rgba(1, 28, 45, 0.3);
            }
            .zh_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.04rem 0.12rem;
                height: 0.35rem;
                font-size: 0.15rem;
                background: transparent;
                border-radius: 0.18rem;
                box-shadow: 0px 2px 36px 0px rgba(1, 28, 45, 0.3);
            }
            .line {
                display: inline-block;
                margin: 0 0.25rem;
                height: 0.17rem;
                width: 0.01rem;
                background: #fff;
            }
            .change_color {
                color: #456ee4;
                background: #fff;
            }
        }
    }
}
</style>