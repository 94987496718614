<template>
    <div class="hub_content_wrapper">
        <p class="hub_content_title">
            {{ $t("Lang.hub_content_title") }}
        </p>
        <ul class="hub_content">
            <li class="hub_item" v-show="!isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/irisLog.png"
                    alt=""
                />
                <a
                    class="href_content"
                    href="https://www.irisnet.org/"
                    target="_blank"
                    >IRIS Hub
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="hub_item" v-show="!isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/atom_icon.png"
                    alt=""
                />
                <a
                    class="href_content"
                    href="https://cosmos.network/"
                    target="_blank"
                    >Cosmos Hub
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="hub_item" v-show="isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/bifrostLog.png"
                    alt=""
                />
                <a
                    class="href_content"
                    href="https://bifrost.iobscan.io/"
                    target="_blank"
                    >Bifrost
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="hub_item" v-show="isTestnet">
                <img
                    class="hub_img"
                    src="../../../assets/images/stargateLog.png"
                    alt=""
                />
                <a
                    class="href_content"
                    href="https://stargate.iobscan.io/"
                    target="_blank"
                    >Stargate
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
            <li class="hub_item">
                <img
                    class="hub_img"
                    src="../../../assets/images/binance_icon.png"
                    alt=""
                />
                <a
                    class="href_content"
                    href="https://www.binance.org"
                    target="_blank"
                    >Binance Chain
                    <i class="el-icon-caret-right"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { NET } from "@/constant/constant";
import { judgeMainOrTest } from "@/utils/utils";
export default {
    name: "Hub",
    computed: {
        isTestnet() {
            return judgeMainOrTest() === NET.TESTNET;
        },
    },
};
</script>

<style lang="less">
.hub_content_wrapper {
    margin-top: 0.43rem;
    .hub_content_title {
        margin-bottom: 0.16rem;
        height: 0.17rem;
        line-height: 0.18rem;
        font-size: 16px;
        font-weight: normal;
    }
    .hub_content {
        display: flex;
        height: 0.2rem;
        line-height: 0.2rem;
        .hub_item {
            margin-right: 0.5rem;
            .hub_img {
                margin-right: 0.1rem;
                width: 0.2rem;
                height: 0.2rem;
                vertical-align: middle;
            }
            .href_content {
                line-height: 0.16rem;
                font-size: 14px;
                color: #fff;
                border-bottom: 0.01rem solid #fff;
                border-radius: 0.01rem;
                @media (max-width: 768px) {
                    border-bottom: 0;
                }
            }
        }
    }
}
</style>