
<template>
    <div class="mobile_footer_content_wrapper">
        <div class="mobile_footer_content">
            <div class="mobile_footer_content_top">
                <div class="protocol_content_wrapper">
                    <span
                        class="user_protocol"
                        @click="switchLang('protocol')"
                        >{{ $t("Lang.notice_link_1") }}</span
                    >
                    <i class="line">|</i>
                    <span
                        class="privacy_policy"
                        @click="switchLang('privacy')"
                        >{{ $t("Lang.notice_link_2") }}</span
                    >
                    <i class="line">|</i>
                    <span class="important_tooltip_btn" @click="updateMask">{{
                        $t("Lang.important_notice")
                    }}</span>
                </div>
            </div>
            <div class="mobile_footer_content_bottom">
                <div class="copyright">
                    {{ $t("Lang.copyrightContent") }}
                </div>
                <ul class="link_content_wrapper">
                    <li class="link_item telegram_link_content">
                        <img
                            class="telegram_img"
                            src="../../assets/images/irisnet_telegram.png"
                            @click="openFn(LINKTYPE.TELEGRAM)"
                        />
                    </li>
                    <li class="link_item medium_link_content">
                        <img
                            class="irisnet_medium_img"
                            src="../../assets/images/irisnet_medium.png"
                            @click="openFn(LINKTYPE.MEDIUM)"
                        />
                    </li>
                    <li class="link_item twitter_link_content">
                        <img
                            class="twitter_img"
                            src="../../assets/images/twitter.png"
                            @click="openFn(LINKTYPE.TWITTER)"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { LANG,SYSTEM,LINKTYPE,MASKTYPE } from "@/constant/constant";
import { getPlatform } from "@/utils/utils";

export default {
    name: "MobileFooter",
    props: ['showMask'],
    data() {
        return {
            LANG,
            LINKTYPE,
            MASKTYPE
        }
    },
    computed: {
        isMobile() {
            return (
                getPlatform() === SYSTEM.IOS || getPlatform() === SYSTEM.ANDROID
            );
        },
    },
    methods: {
        updateMask() {
            this.$emit('update:showMask', !this.showMask);
        },
        openFn(type) {
            if (type === LINKTYPE.TELEGRAM) {
                window.open("https://t.me/irisnetworkcn");
            } else if (type === LINKTYPE.MEDIUM) {
                window.open("https://medium.com/irisnet-blog");
            } else if (type === LINKTYPE.TWITTER) {
                window.open("https://twitter.com/WalletRainbow");
            }
        },
        // 服务协议及隐私政策语言区分
        switchLang(type) {
            if (type === MASKTYPE.PROTOCOL) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/protocol_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/protocol_en.html");
                }
            } else if (type === MASKTYPE.PRIVACY) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open("/privacy_zh.html");
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open("/privacy_en.html");
                }
            } else if (type === MASKTYPE.MNEMONIC) {
                if (this.$i18n.locale === LANG.ZH) {
                    window.open(
                        "https://mp.weixin.qq.com/s/ljG4taUvNyFzOb63ZgPd7A"
                    );
                } else if (this.$i18n.locale === LANG.EN) {
                    window.open(
                        "https://medium.com/@irisnet/important-things-when-backing-up-mnemonic-3-dos-and-3-donts-a9dd15797451"
                    );
                }
            }
        },
    },
};
</script>

<style lang="less">
.mobile_footer_content_wrapper {
    width: 100%;
    .mobile_footer_content {
        width: 100%;
        text-align: center;
        .mobile_footer_content_top {
            width: 100%;
            .protocol_content_wrapper {
                font-size: 0.14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: 0.16rem;
                .line {
                    display: inline-block;
                    margin: auto 0.2rem;
                    width: 0.01rem;
                    height: 0.15rem;
                    font-style: normal;
                    @media (max-width: 490px) {
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
                .important_tooltip_btn {
                    @media (max-width: 490px) {
                        display: block;
                        margin-top: 0.12rem;
                    }
                }
            }
        }
        .mobile_footer_content_bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.32rem;
            font-size: 0.14rem;
            color: rgba(255, 255, 255, 0.7);
            line-height: 0.16rem;
            @media (max-width: 490px) {
                flex-direction: column-reverse;
                margin-top: 0.41rem;
            }
            .copyright {
                @media (max-width: 490px) {
                    margin-top: 0.19rem;
                }
            }
            .link_content_wrapper {
                display: flex;
                align-items: center;
                margin-left: 0.2rem;
                @media (max-width: 490px) {
                    margin-left: 0;
                }
                .link_item {
                    margin-left: 0.2rem;
                    height: 0.2rem;
                    opacity: 0.7;
                    &:first-child {
                        margin-left: 0;
                    }
                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
    .mobile_mask {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(32, 32, 32, 0.73);
    }
}
</style>